<template>
  <div class="mt-1 mx-3">
    <cooperators-create> </cooperators-create>
  </div>
</template>

<script>
import CooperatorsCreate from "@/components/profile/cooperators/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CooperatorsCreate,
  },
  created() {
    this.notifyQueue({
      text: "Crea Collaboratore",
      path: "brokers.create1",
      level: 3,
    });
  },
};
</script>
